import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/esnext.typed-array.find-last.js";
import "core-js/modules/esnext.typed-array.find-last-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import Kilt from "@kiltprotocol/sdk-js";
import CryptoJS from "crypto-js";
import { u8aToHex } from "@polkadot/util";
import { queryGeneticAnalystByAccountId, queryGeneticAnalysisByGeneticAnalysisTrackingId, queryGeneticAnalysisOrderById, queryGeneticAnalystServicesByHashId } from "@debionetwork/polkadot-provider";
import { downloadFile, decryptFile, downloadDocumentFile } from "@/common/lib/pinata-proxy";
import { chevronLeftIcon } from "@debionetwork/ui-icons";
export default {
  name: "GeneticDataResult",
  data: () => ({
    chevronLeftIcon,
    isLoading: false,
    publicKey: null,
    secretKey: null,
    result: null,
    message: "Please wait",
    details: {}
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      mnemonicData: state => state.substrate.mnemonicData
    }),
    computeAnalystName() {
      var _this$details, _this$details$analyst, _this$details2, _this$details2$analys;
      return "".concat((_this$details = this.details) === null || _this$details === void 0 ? void 0 : (_this$details$analyst = _this$details.analyst) === null || _this$details$analyst === void 0 ? void 0 : _this$details$analyst.firstName, " ").concat((_this$details2 = this.details) === null || _this$details2 === void 0 ? void 0 : (_this$details2$analys = _this$details2.analyst) === null || _this$details2$analys === void 0 ? void 0 : _this$details2$analys.lastName);
    }
  },
  watch: {
    mnemonicData(val) {
      if (val) this.initialData();
    }
  },
  async created() {
    if (this.mnemonicData) this.initialData();
  },
  methods: {
    async initialData() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.secretKey = u8aToHex(cred.boxKeyPair.secretKey);
      if (cred) await this.prepareData();
    },
    async prepareData() {
      var _this$details3, _this$details3$analys;
      const {
        serviceId,
        sellerId,
        geneticAnalysisTrackingId,
        status
      } = await queryGeneticAnalysisOrderById(this.api, this.$route.params.id);
      const geneticData = await queryGeneticAnalysisByGeneticAnalysisTrackingId(this.api, geneticAnalysisTrackingId);
      if (geneticData.status !== "ResultReady" || status === "Refunded") this.$router.push({
        name: "customer-genetic-analysis-detail",
        params: {
          id: this.$route.params.id
        }
      });
      const service = await queryGeneticAnalystServicesByHashId(this.api, serviceId);
      const analyst = await queryGeneticAnalystByAccountId(this.api, sellerId);
      const parseDate = date => {
        return new Date(parseInt(date.replaceAll(",", ""))).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric"
        });
      };
      this.details = {
        service: service.info,
        analyst: analyst.info,
        geneticData: {
          ...geneticData,
          lastUpdate: parseDate(geneticData.updatedAt)
        }
      };
      this.publicKey = (_this$details3 = this.details) === null || _this$details3 === void 0 ? void 0 : (_this$details3$analys = _this$details3.analyst) === null || _this$details3$analys === void 0 ? void 0 : _this$details3$analys.boxPublicKey;
      this.parseResult(geneticData.reportLink);
    },
    async parseResult(recordLink) {
      let fileChunks = [];
      let fileType;
      const computeFileName = "Result";
      try {
        const pair = {
          publicKey: this.publicKey,
          secretKey: this.secretKey
        };
        this.isLoading = true;
        if (/^\[/.test(recordLink)) {
          const links = JSON.parse(recordLink);
          for (let i = 0; i < links.length; i++) {
            const {
              type,
              data
            } = await downloadFile(links[i], true);
            const decryptedFile = decryptFile([data], pair, type);
            fileType = type;
            fileChunks = [...fileChunks, ...(decryptedFile ? decryptedFile : [])];
          }
        } else {
          const {
            type,
            data
          } = await downloadFile(recordLink, true);
          const decryptedFile = decryptFile([data], pair, type);
          fileType = type;
          fileChunks = [...fileChunks, ...(decryptedFile ? decryptedFile : [])];
        }
        const unit8arrays = new Uint8Array(fileChunks);
        await downloadDocumentFile(unit8arrays, computeFileName, fileType);
      } catch {
        this.message = "Oh no! Something went wrong. Please try again later";
      } finally {
        this.isLoading = false;
      }
    },
    handlePrevious() {
      this.$router.go(-1);
    }
  }
};